<template>
  <div class="site-vendor-categories">
    <div class="row">
      <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
        <b-form-group
          id="siteId"
          label="Select website"
          label-for="siteId"
          :label-class="['form-label', 'required']"
        >
          <b-dropdown
            id="dropdown-form"
            :text="formData.siteId == '' ? 'Select site' : selectedSite.text"
            ref="dropdown"
            block
            menu-class="w-100"
            no-caret
            lazy
            variant="brand-dropdown"
          >
            <b-dropdown-form @submit.prevent>
              <div class="form-group">
                <b-form-input
                  type="search"
                  class="nosubmit w-100"
                  placeholder="Search"
                  v-model="siteSearchText"
                />
              </div>
              <div class="scrollable">
                <b-dropdown-item
                  v-for="(site, index) in sitesList"
                  :key="index"
                  @click="formData.siteId = site.value"
                  :active="formData.siteId == site.value"
                  >{{ site.text }}
                </b-dropdown-item>
              </div>
            </b-dropdown-form>
          </b-dropdown>
        </b-form-group>
      </div>
      <div class="col-12 mb-3" v-if="formData.siteId != ''">
        <div class="row row-no-gutters">
          <div
            class="col-xl-2 col-lg-2 col-md-3 col-sm-12"
            v-for="(option, index) in allowOptions"
            :key="'allowOption_' + index"
          >
            <b-form-checkbox
              :id="option.text"
              v-model="formData[option.formDataKey]"
              :name="option.text"
              :value="1"
              :unchecked-value="0"
              :disabled="formData.siteId == 0"
            >
              {{ option.text }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>
    <b-card v-if="formData.siteId == 0">
      <div class="d-flex justify-content-center">
        <error-message
          message="Please select a site to display vendors and categories"
        />
      </div>
    </b-card>
    <b-card v-else-if="vendorData.length == 0">
      <div class="d-flex justify-content-center">
        <error-message message="No site vendor categories found." />
      </div>
    </b-card>
    <b-card v-else>
      <div class="product-filters scrollable-vendor_categries">
        <div
          class="position-relative"
          v-for="{ data, vendor_ID, vendorName } in vendorData"
          :key="vendor_ID"
        >
          <b-button
            class="btn-group border-right-0 border-left-0 rounded-0"
            variant="brand-accordion"
            :class="visible[`${vendor_ID}`] ? null : 'collapsed'"
            :aria-expanded="visible[`${vendor_ID}`] ? 'true' : 'false'"
            :aria-controls="vendor_ID"
            @click="
              toggleVendorCollapse({
                vendor_ID,
                vendorName,
              })
            "
          >
            {{ vendorName }}</b-button
          >
          <b-collapse :id="vendor_ID" v-model="visible[`${vendor_ID}`]">
            <div
              class="position-relative"
              v-for="{
                data: roomCategoryData,
                roomCategoryID,
                roomCategoryName,
              } in data"
              :key="vendor_ID + '_roomCategory_' + roomCategoryID"
            >
              <b-button
                class="sub-accordion text-dark font-weight-bold border-right-0 border-left-0 rounded-0"
                variant="brand-accordion"
                :class="
                  visible[`${vendor_ID}_roomCategory_${roomCategoryID}`]
                    ? null
                    : 'collapsed'
                "
                :aria-expanded="
                  visible[`${vendor_ID}_roomCategory_${roomCategoryID}`]
                    ? 'true'
                    : 'false'
                "
                :aria-controls="vendor_ID + '_roomCategory_' + roomCategoryID"
                @click="
                  visible[`${vendor_ID}_roomCategory_${roomCategoryID}`] =
                    !visible[`${vendor_ID}_roomCategory_${roomCategoryID}`]
                "
                >{{ roomCategoryName }}
              </b-button>
              <b-collapse
                :id="vendor_ID + '_roomCategory_' + roomCategoryID"
                v-model="visible[`${vendor_ID}_roomCategory_${roomCategoryID}`]"
              >
                <b-card no-body class="border-0">
                  <div class="pt-2 pr-0 pl-0 pr-md-4 pl-md-4">
                    <div class="row row-no-gutters">
                      <div
                        class="col-xl-6 col-lg-6 col-md-12 pb-2"
                        v-for="(roomData, index) in roomCategoryData"
                        :class="
                          index == roomCategoryData.length - 1 ? '' : 'pb-2'
                        "
                        :key="
                          vendor_ID +
                          '_' +
                          roomCategoryID +
                          '_' +
                          roomData.value
                        "
                      >
                        <b-form-checkbox
                          :id="
                            vendor_ID +
                            '_' +
                            roomCategoryID +
                            '_' +
                            roomData.value
                          "
                          v-model="roomData.allow"
                          :name="roomData.text"
                          :value="1"
                          :unchecked-value="0"
                          @change="
                            detectCategoryChange({
                              siteId: formData.siteId,
                              subCategoryId: roomData.value,
                              vendorId: vendor_ID,
                              allow: roomData.allow,
                              roomCategoryID: roomCategoryID,
                            })
                          "
                        >
                          {{ roomData.text }}
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-collapse>
            </div>
            <!-- </b-card> -->
          </b-collapse>
        </div>
      </div>
      <div
        class="d-flex flex-row-reverse mt-3"
        v-if="formData.siteId != 0 && vendorData.length != 0"
      >
        <b-button
          @click="handleUpdate"
          variant="brand-primary"
          :disabled="disableSubmitButton"
          >Update</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import {
  forEach,
  cloneDeep,
  findIndex,
  chain,
  isEqual,
  orderBy,
  toLower,
} from "lodash";
import ErrorMessage from "../../../../components/ErrorMessage.vue";

const createDefault = function () {
  return {
    siteId: 0,
    allowAdd: 0,
    allowUpdate: 0,
    allowDelete: 0,
  };
};

export default {
  name: "VendorCategoriesList",
  components: { ErrorMessage },
  data() {
    return {
      // showAlert: false,
      siteSearchText: "",
      sitesList: [],
      selected: [],
      // formData: {
      //   siteId: 0,
      //   allowAdd: 0,
      //   allowUpdate: 0,
      //   allowDelete: 0,
      // },
      compareformData: createDefault(),
      formData: createDefault(),
      allowOptions: [
        { text: "Allow Add", formDataKey: "allowAdd" },
        { text: "Allow Update", formDataKey: "allowUpdate" },
        { text: "Allow Delete", formDataKey: "allowDelete" },
      ],
      vendorData: [],
      vendorDataChanges: [],
      visible: {},
    };
  },
  watch: {
    "formData.siteId": async function (newVal) {
      // this.showAlert = false;
      this.formData.allowAdd = 0;
      this.formData.allowUpdate = 0;
      this.formData.allowDelete = 0;

      let selectedSite = this.sitesList.find((site) => site.value == newVal);

      this.setItem({ resource: "isLoading", payload: true });

      this.setItem({ resource: "selectedSite", payload: selectedSite });
      const result = await this.getSiteSettings({
        id: newVal,
        userId: this.loggedUserId,
      });

      await this.getSiteVendorCategory({
        id: newVal,
        userId: this.loggedUserId,
      });

      this.compareformData = { ...this.compareformData, ...result };
      this.formData = { ...this.formData, ...result };

      this.setItem({ resource: "isLoading", payload: false });
    },
    storeList(newVal) {
      if (newVal.length > 0) {
        let options = [];
        forEach(this.storeList, (site) => {
          let obj = {
            text: site.storeName,
            value: site.storeId,
          };
          options.push(obj);
        });
        this.sitesList = options;
      }
    },
    siteSearchText(newValue) {
      if (this.storeList.length > 0) {
        let newOptions = [];
        forEach(this.storeList, (site) => {
          if (
            site.storeName.toLowerCase().includes(newValue.toLowerCase()) ||
            newValue == ""
          ) {
            let obj = { text: site.storeName, value: site.storeId };
            newOptions.push(obj);
          }
        });
        this.sitesList = newOptions;
      }
    },

    vendorCategories(newVal) {
      if (newVal.length == 0) {
        this.vendorData = [];
      } else {
        const output = chain(newVal)
          .groupBy("vendor_ID")
          .map((value, key) => ({
            vendor_ID: key,
            vendorName: value[0].vendorName,
            data: chain(value)
              .groupBy("roomCategoryID")
              .map((value, key) => ({
                roomCategoryID: key,
                roomCategoryName: value[0].roomCategoryName,
                data: chain(value)
                  .map((value) => ({
                    text: value.sectionCategoryName,
                    value: value.sectionCategoryID,
                    allow: value.allow,
                    option: {
                      siteId: value.siteId,
                      subCategoryId: value.sectionCategoryID,
                      vendorId: value.vendor_ID,
                      allow: value.allow,
                    },
                  }))
                  .value(),
              }))
              .value(),
          }))
          .value();

        const sortedOutput = orderBy(
          output,
          [(_value) => toLower(_value.vendorName)],
          ["asc"]
        ).map((value) => {
          let sortedByCatName = orderBy(
            value.data,
            [(_value) => toLower(_value.roomCategoryName)],
            ["asc"]
          ).map((catValue) => {
            const sortedCatValue = orderBy(
              catValue.data,
              [(_value) => toLower(_value.text)],
              ["asc"]
            );
            return { ...catValue, data: sortedCatValue };
          });
          return { ...value, data: sortedByCatName };
        });

        let _visibleCollapseData = {};
        forEach(sortedOutput, (op) => {
          _visibleCollapseData[`${op.vendor_ID}`] = false;
          forEach(op.data, (op_data) => {
            _visibleCollapseData[
              `${op.vendor_ID}_roomCategory_${op_data.roomCategoryID}`
            ] = false;
          });
        });

        this.vendorData = sortedOutput;
        this.visible = _visibleCollapseData;
      }
    },
  },
  computed: {
    ...mapGetters(["selectedSite"]),
    ...mapGetters("siteVendorCategory", ["vendorCategories"]),
    ...mapGetters("siteManager", ["storeList"]),
    disableSubmitButton() {
      return this.vendorDataChanges.length > 0 ||
        !isEqual(this.formData, this.compareformData)
        ? false
        : true;
    },
  },
  methods: {
    ...mapMutations(["setItem"]),
    ...mapActions("siteVendorCategory", [
      "getSiteSettings",
      "manageSiteSettings",
      "getSiteVendorCategory",
      "manageSiteVendorCategoryMapping",
    ]),
    ...mapActions("siteManager", ["getStoreList"]),
    async handleUpdate() {
      this.setItem({ resource: "isLoading", payload: true });
      await this.manageSiteSettings({
        ...this.formData,
        userId: this.loggedUserId,
      });

      if (this.vendorDataChanges.length > 0) {
        await this.manageSiteVendorCategoryMapping({
          siteId: this.formData.siteId,
          vendorDataChanges: this.vendorDataChanges,
          userId: this.loggedUserId,
        });
      }

      const resultSettings = await this.getSiteSettings({
        id: this.formData.siteId,
        userId: this.loggedUserId,
      });

      this.compareformData = { ...this.compareformData, ...resultSettings };
      this.formData = { ...this.formData, ...resultSettings };

      await this.getSiteVendorCategory({
        id: this.formData.siteId,
        userId: this.loggedUserId,
      });

      this.vendorDataChanges = [];

      this.setItem({ resource: "isLoading", payload: false });
      window.scroll({ top: 0, behavior: "smooth" });
      const h = this.$createElement;

      const titleVNode = h("h5", {
        class: ["modal-title font-weight-bold"],
        domProps: {
          innerHTML: "Success!",
        },
      });

      const messageVNode = h("p", { class: ["text-center m-0 font-14"] }, [
        // this.sytemMessages.csvFileUploadWarning,
        this.sytemMessages.vendorCategoryUpdated,
      ]);

      this.$bvModal.msgBoxOk([messageVNode], {
        // title: "Caution",
        title: [titleVNode],
        size: "sm",
        dialogClass: "confirm-import-modal",
        buttonSize: "sm",
        okVariant: "brand-primary w-100",
        okTitle: "Close",
        headerClass: "justify-content-center p-0 mt-3 border-bottom-0",
        footerClass: "p-3 border-top-0 flex-nowrap",
        hideHeaderClose: true,
        centered: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
      });
      // this.showAlert = true;
    },
    async detectCategoryChange(option) {
      // vendorDataChanges

      let _vendorDataChanges = cloneDeep(this.vendorDataChanges);

      let index = findIndex(
        this.vendorDataChanges,
        (e) => {
          return (
            e.siteId == option.siteId &&
            e.subCategoryId == option.subCategoryId &&
            e.vendorId == option.vendorId
          );
        },
        0
      );

      if (index != -1) {
        _vendorDataChanges.splice(index, 1);
      }

      let old_data = this.vendorCategories.find(
        (item) =>
          item.roomCategoryID == option.roomCategoryID &&
          item.sectionCategoryID == option.subCategoryId &&
          item.vendor_ID == option.vendorId
      );

      if (
        old_data &&
        Object.keys(old_data).lenght != 0 &&
        old_data.allow != option.allow
      ) {
        delete option.roomCategoryID;
        _vendorDataChanges.push(option);
      }

      this.vendorDataChanges = _vendorDataChanges;
    },
    toggleVendorCollapse(data) {
      this.visible[`${data.vendor_ID}`] = !this.visible[`${data.vendor_ID}`];

      for (const [key] of Object.entries(this.visible)) {
        if (key.startsWith(`${data.vendor_ID}_roomCategory_`)) {
          this.visible[`${key}`] =
            !this.visible[`${data.vendor_ID}`] == false
              ? false
              : this.visible[`${key}`];
        }
      }
    },
  },
  async mounted() {
    this.setItem({ resource: "isLoading", payload: true });
    this.sitesList = [];
    await this.getStoreList({ userId: this.loggedUserId });
    this.setItem({ resource: "isLoading", payload: false });
  },
};
</script>
